export const SportsFan = `
fragment SportsFan on sportsFan {
    __typename
    id
    isCeleb
    name
    photo
    isFollowing
  }    
`;

export const Broadcaster = `
fragment Broadcaster on broadcaster {
    __typename
    id
    recordStream
    followerCount
    sportsFan {
      __typename
      ...SportsFan
    }
  }
  ${ SportsFan }
`;

export const BroadcastSession = `
fragment BroadcastSession on broadcastSession {
    __typename
    id
    broadcaster {
      __typename
      ...Broadcaster
    }
    startTimeUTC
    endTimeUTC
    remainingWatchDuration
    isLive
    streamTier
    cdnUrl
    streamingURL
	  seekFeatureEnabled
    mediaType
    midTierRemainingAdWatchCount
    showAds
    sessionInfo
    sessionType
    description
    resolution
    thumbnail
    totalReactions
    totalComments
    totalShares
    totalViewedMinutes
    androidPackageName
    restrictStreamQualityOnDesktop
    isLeaderBoardLive
    recordStream
    localeKey
    game {
      name
      banner
      image
      id
    }
    tags {
      tagId
      tagDisplayName
      tagName
    }
    totalViews
    views
    liveViews
    sortingScore
    playWithFriends
    donationGoal
	  isIvsStream
	  ivsChatEnabled
    pollsEnabled
	  giveAwayCoins
    followerOnlyChat
    donorOnlyChat
    hasUserDonated
    firestoreDisabled
    isReacted
    isCurrentUserModerator
    pinnedCommentDetails {
      id
      commentText
      commentTags
      donationProductUrl
      sportsFan {
        id
        isCeleb
        name
        photo
        isFollowing
      }
    }
  }
  ${ Broadcaster }
`;

export const BroadcastTrendingTopic = `
fragment BroadcastTopic on broadcastTrendingTopic {
  id
  topic
  displayName
}
`;

export const StreamingToolLevel = `
fragment Levels on streamingToolLevel {
  __typename
  id
  level
  coins
  duration
  toolId
  enabled
  renew
  purchaseId
  totalDuration
}`;

export const contentLocale = `
fragment ContentLocales on contentLocale{
	locale
	name
	image
 }`;

export const TopDonorsLeaderBoard = `
fragment TopDonorsLeaderBoard on streamDonorsLeaderboard {
  __typename
  currentUser {
    userId
    userSportsFanId
    donation
    rank
    sportsFan {
      __typename
      ...SportsFan
    }
  }
  leaderboard {
    userId
    userSportsFanId
    donation
    rank
    sportsFan {
      __typename
      ...SportsFan
    }
  }
  totalDonation
}
${ SportsFan }
`;

export const CommentSchema = `
fragment CommentSchema on commentSchema {
  __typename
  id
  broadcastSessionId
  commentText
  createdAt
  duringLive
  type
  commentType
  donationProductId
  donationProductUrl
  debitCurrencyId
  debitValue
  totalGemsDebited
  totalGemsCredited
  tags
  commentTags
  isMagicChat
  pinningDuration
  sportsFan {
    __typename
    id
    name
    isCeleb
    photo
    city
    locale
    fanType
    userSportsFanId
    isLive
    shortBio
    followerCount
    isFollowing
    followingStatus {
      __typename
      isFollowing
      createdAt
    }
    ugcStats {
      __typename
      postCount
    }
  }
}
`;
