import {
	BroadcastSession,
	Broadcaster,
	CommentSchema,
	SportsFan,
	StreamingToolLevel,
	contentLocale
} from "./fragments";

export const GetBroadcastSessions_Q1 = () => {
	return `query broadcastSession($pageNo: Int, $pageSize: Int, $sportsFanId: Int) {
				broadcastSessionsBySportsFanId(
					pageNo: $pageNo, 
					pageSize: $pageSize, 
					sportsFanId: $sportsFanId
					liveSessions: 1
				){
					id
					sessionInfo
					thumbnail
					localeKey
				} 
			}`;
};

export const personalizedLiveData = () => {
	return `query personalizedLiveData($pageNo: Int, $pageSize: Int) {
        personalizedLiveData(pageNo: $pageNo, pageSize: $pageSize) {
			recommendedStreamers {
				userSportsFanId
				name
				photo
				isCeleb
				isFollowing
				followerCount
				isLive
				shortBio
				ugcStats{
				  postCount
				}
			  }
			}
    	}`;
};

export const liveTabData = () => {
	return `query GetliveTabOrder($pageNo:Int!, $pageSize:Int!){
		liveTabOrdering(pageNo: $pageNo, pageSize: $pageSize) {
		  cardId
		  cardName
		  cardType
		  cardOrder
		  displayName
		}
	  }`;
};

export const getPollsConfig = () => {
	return `query getPollsConfig{
		pollsConfigure {
          titleLengthMin
          titleLengthMax
          optionLengthMin
          optionLengthMax
          numberOfOptionsMin
          numberOfOptionsMax
		}
	  }`;
};

export const tournamentData = () => {
	return `query GetTournaments($pageNo:Int, $pageSize:Int,$tournamentIds:[Int]){
		tournaments(pageNo: $pageNo, pageSize: $pageSize,tournamentIds:$tournamentIds){
		id
		image
		uniqueViews
		streamCount
		sessions {
			__typename
			...BroadcastSession
		 }
		}
	}${ BroadcastSession }`;
};

export const tournamentDataWithoutSessions = () => {
	return `query GetTournaments($pageNo:Int, $pageSize:Int,$tournamentIds:[Int]){
		tournaments(pageNo: $pageNo, pageSize: $pageSize,tournamentIds:$tournamentIds){
		id
		image
		uniqueViews
		streamCount
		}
	}`;
};

export const personalizedLiveDataBroadcastIds = () => {
	return `query personalizedLiveData($pageNo: Int, $pageSize: Int) {
		personalizedLiveData(pageNo: $pageNo, pageSize: $pageSize) {
		 recommendedSessions{
		  sessions{
			id
			
		  }
		}
		}
	  }`;
};

export const SearchBroadcastSessions = broadcastIds => {
	return `query GetTrendingBroadcastSessions {
						broadcastSessionByIds(
							ids: [${ broadcastIds }]
						){
							__typename
							...BroadcastSession
						}
					} ${ BroadcastSession }`;
};

export const GetBroadcastSession = () => {
	return `query GetBroadcastSession($sessionId: Int!) {
		broadcastSession(id: $sessionId)
		{    __typename
		...BroadcastSession
	  }}
	  ${ BroadcastSession }`;
};

export const GetTrendingBroadcastSessions_Q4 = () => {
	return `query GetTrendingBroadcastSessions (
		$pageNo: Int!, $pageSize: Int!, 
		$mergedBroadcasts: Int, $gamingSessions: Int, 
		$isLive:Int, $androidPackageName: String
	) {
		  trendingBroadcastSessions(
					pageNo: $pageNo
					pageSize: $pageSize
					mergedBroadcasts: $mergedBroadcasts
					gamingSessions: $gamingSessions
					isLive: $isLive
					androidPackageName: $androidPackageName
		  ){    __typename
			  ...BroadcastSession
			}
		  }
		   ${ BroadcastSession }`;
};

export const GetTrendingBroadcastSessions_Q8 = () => {
	return `query GetTrendingBroadcastSessions(
		$pageNo: Int!, 
		$pageSize: Int!,
		$isLive:Int,
		$androidPackageName: String
	) {
		  trendingBroadcastSessions(
					pageNo: $pageNo
					pageSize: $pageSize
					isLive: $isLive
					androidPackageName: $androidPackageName
		  ){    __typename
			  ...BroadcastSession
			}
		  }
		   ${ BroadcastSession }`;
};

export const GetTrendingBroadcastSessions_Q5 = () => {
	return `query GetTrendingBroadcastSessions (
				$pageNo: Int!, $pageSize: Int!,
				$mergedBroadcasts: Int, $gamingSessions: Int,
				$isLive:Int, $isTopic: Int,
				$androidPackageName: String
	){
          trendingBroadcastSessions(
                    pageNo: $pageNo
                    pageSize: $pageSize
                    mergedBroadcasts: $mergedBroadcasts
                    gamingSessions: $gamingSessions
                    isLive: $isLive
                    isTopic: $isTopic
                    androidPackageName: $androidPackageName 
              ){  
                id
                broadcaster {
                  __typename
                  ...Broadcaster
                }
                isLive
                mediaType
                sessionType
                androidPackageName
				streamTier
                thumbnail
                totalReactions
                totalComments
                totalShares
				game {
                  name
                  androidPackageName
                  banner
                }
                views
                topic {
                  displayName
                }
                sessionInfo
                startTimeUTC
				endTimeUTC
				cdnUrl
                liveViews
				totalViews
                coHostSportsFans {
                  ...SportsFan
                }
              }
            }
            ${ Broadcaster }
          `;
};

export const GetTrendingBroadcastSessions_Q6 = () => {
	return `query GetTrendingBroadcastSessions ($pageNo:Int!, $pageSize:Int!, $mergedBroadcasts:Int, $isLive:Int){
			trendingBroadcastSessions(
					  pageNo           : $pageNo
					  pageSize         : $pageSize
					  mergedBroadcasts : $mergedBroadcasts
					  isLive           : $isLive
			){    __typename
				...BroadcastSession
			  }
			}
		   ${ BroadcastSession }
			`;
};

export const GetTrendingBroadcastSessions_Q7 = () => {
	return `query GetTrendingBroadcastSessions($sessionId: Int!) {
			broadcastSession(
					id: $broadcastId                                                                                                                                              
		  ){    __typename
				playWithFriends

			}}`;
};

export const getFanLeaderboard_Q1 = ( { pageNo, pageSize, broadcasterSportsFanId, date } ) => {
	return `query getFanLeaderboard {
		  fanLeaderBoard(
			broadcasterSportsFanId: ${ broadcasterSportsFanId }
			pageNo: ${ pageNo }
			pageSize: ${ pageSize }
			date: ${ JSON.stringify ( date ) }

		){    __typename
			  distributionStatus

			  leaderboard {
				userId
				score
				rank
				coins
				sportsFan {
				  ...SportsFan
				}
			  }
			  currentUser{
				userId
				score
				rank
				coins
				sportsFan {
				  ...SportsFan
				}
               }
		  }}
		  ${ SportsFan }
		`;
};

export const getUserBroadcasts_Q1 = ( { sportsFanId, pageNo, pageSize } ) => {
	return `query getUserBroadcasts {
			broadcastSessionsBySportsFanId(sportsFanId: ${ sportsFanId }, 
				pageNo: ${ pageNo }, 
				pageSize: ${ pageSize }) 
			{
			  __typename
			  id
			  broadcaster {
				__typename
				...Broadcaster
			  }
			  isLive
			  mediaType
			  sessionType
			  androidPackageName
			  thumbnail
			  midTierRemainingAdWatchCount
			  giveAwayCoins
			  isLeaderBoardLive
    		  isReacted
			  cdnUrl
			  streamTier
			  followerOnlyChat
			  donationGoal
			  recordStream
			  streamingURL
			  game {
				name
				androidPackageName
				banner
				id
				image
				totalStreams
				appUrl
				banner
				currentStreamCount
			  }
			  tags {
				tagId
				tagDisplayName
				tagName
			  }
			  localeKey
			  views
			  totalViews
			  totalReactions
			  totalComments
			  totalShares
			  topic {
				displayName
			  }
        pinnedCommentDetails {
          id
          commentText
          commentTags
          donationProductUrl
          sportsFan {
            id
            isCeleb
            name
            photo
            isFollowing
          }
        }
			  sessionInfo
			  startTimeUTC
			  endTimeUTC
			  liveViews
			  description
			  coHostSportsFans {
				...SportsFan
			  }
			}
		  }
		  ${ Broadcaster }`;
};

export const getUserBroadcasts_Q2 = ( { sportsFanId, pageNo, pageSize } ) => {
	return `query getUserBroadcasts {
              broadcastSessionsBySportsFanId(sportsFanId: ${ sportsFanId }, 
                  pageNo: ${ pageNo }, 
                  pageSize: ${ pageSize }) 
              {
                __typename
                id
                endTimeUTC
                deviceType
              }
            }`;
};

export const GetBroadcasterDetails_Q1 = ( { userId } ) => {
	return `query GetBroadcasterDetails {
					broadcaster(sportsFanId:${ userId }){
						totalListeningMinutes
						totalSessionCount    
						totalBroadcastingMinutes   
					}
				}`;
};

export const GetGamingBroadcastSessions_Q1 = ( { pageNo = 1, pageSize = 10 } ) => {
	return `query GetGamingBroadcastSessions {
          gamingBroadcastSessions(pageNo: ${ pageNo } pageSize: ${ pageSize } thirdParty: ${ 0 } ) {
            gamingSessions {
              remainingCount
              nextBroadcasterId
              nextBroadcasterImage
              sessions {
                id
                mediaType
                sessionType
                broadcaster {
                  ...Broadcaster
                }
                liveViews
                coHostSportsFans {
                  ...SportsFan
                }
                game {
                  name
                  androidPackageName
                  image
                  banner
                }
                totalReactions
                totalComments
                totalShares
                thumbnail
                sessionInfo
                topic {
                  displayName
                }
                androidPackageName
                startTimeUTC
                views
                isLive
				}
            }
          }
        }
        ${ Broadcaster }
        `;
};

export const getNginxRtmpUrls = ( { resetStreamKey, sessionType } ) => {
	return `query getNginxRtmpUrls {
                  getNginxRtmpUrls(
					resetStreamKey: ${ resetStreamKey }
					sessionType: "${ sessionType }"
				  ){
            __typename
            rtmp
            cdn
            streamKey
						cssStreamName
						ivsChannelId
                  }
                }`;
};

export const popularStreamingGames = ( { pageNo = 1, pageSize = 10, isDesktopGame = 1, sortByPriority = 0, gameIds = null } ) => {
	return `query popularStreamingGames {
                  popularStreamingGames(
                    pageNo: ${ pageNo }
                    pageSize: ${ pageSize }
                    isDesktopGame: ${ isDesktopGame }
					sortByPriority: ${ sortByPriority }
					gameIds: ${ gameIds }
                  ) {
                                          __typename
                                          id
                                          name
                                          androidPackageName
                                          image
                                          totalStreams
                                          appUrl
                                          banner
										  currentStreamCount
										  gameDescription
                  }
                }`;
};

export const popularStreamingGamesName = () => {
	return `query popularStreamingGames ($gameNames: [String],) {
                  popularStreamingGames(
					gameNames : $gameNames
                  ) {
                        __typename
                        id
                        name
                  }
                }`;
};

export const streamTools = () => {
	return `query streamTools {
                    streamingTools {
                      __typename
                      id
                      type
                      displayName
                      coins
                      description
                      icon
                      hasLevels
                      levels {
                        ...Levels
                      }
                      enabled
                    }
                  }
                ${ StreamingToolLevel }
                `;
};

export const GetCarouselSessions = () => {
	return `query GetCarouselSessions ($pageNo: Int!, $pageSize: Int!) {
			carouselSessions(
					  pageNo: $pageNo
					  pageSize: $pageSize
			){    __typename
				...BroadcastSession
			  }}
			${ BroadcastSession }
			`;
};

export const getPopularGames = () => {
	return `query getPopularGames($pageNo:Int, $pageSize:Int,$gameCardIds:[Int]){
				popularGameCards(pageNo: $pageNo, pageSize: $pageSize,gameCardIds:$gameCardIds) {
				  __typename
				  id
				  name
				  androidPackageName
				  image
				  uniqueViews
				  streamCount
				  sessions {
					__typename
					...BroadcastSession
				 }
				}
			}${ BroadcastSession }`;
};

export const getPopularGamesWithoutSessions = () => {
	return `query getPopularGames($pageNo:Int, $pageSize:Int,$gameCardIds:[Int]){
				popularGameCards(pageNo: $pageNo, pageSize: $pageSize,gameCardIds:$gameCardIds) {
				  __typename
				  id
				  name
				  androidPackageName
				  image
				  uniqueViews
				  streamCount
				}
			}`;
};

export const customThumbnails = ( { pageNo, pageSize } ) => {
	return `query customThumbnails {
                  customThumbnails(pageNo: ${ pageNo } pageSize: ${ pageSize }) {
                    __typename
                    thumbnailId
                    thumbnailUrl
                    gameId
                    coins
                    isPurchasedAlready
                  }
                }`;
};

export const purchaseStatusStreamingTool = ( { toolType, toolId } ) => {
	return `query purchaseStatusStreamingTool {                                                                                                                         
                  purchaseStatusStreamingTool(typeOfTool: ${ JSON.stringify ( toolType ) } toolId: ${ toolId }) {
                    isPurchased
                    purchaseDateUtc
                  }
                }`;
};

export const overlayTypes = ( { pageNo, pageSize } ) => {
	return `query overlayTypes {
          overlayTypes(pageNo: ${ pageNo } pageSize: ${ pageSize }) {
                    __typename
                    id
                    overlayType
                  }
                }`;
};

export const customOverlays = ( { pageNo, pageSize, overlayTypeId } ) => {
	return `query customOverlays {
			customOverlays(pageNo: ${ pageNo } pageSize: ${ pageSize }, overlayTypeId:${ overlayTypeId === -1 ? null : overlayTypeId }) {
					  __typename
					  overlayId
					  overlayUrl
					  coins
					  isPurchasedAlready
					  overlayTypeId
					}
				  }`;
};

export const getFollowersLive = () => {
	return `query {
		followersLive(pageNo:1, pageSize:100) {
		  id
		  sessionInfo
		  sessionType
		  endTimeUTC
		  liveViews
		  isLive
		  game{
			name
			id
			androidPackageName
		  }
		  broadcaster{
			id
			sportsFan{
			  id
			  name
			  photo
			  isCeleb
			}
		  }
		}
	  }`;
};

export const getOnboardingGames = () => {

	return `query getPopularStreamingGames($pageSize: Int) {
		games:popularStreamingGames(pageSize:$pageSize,isDesktopGame:0,sortByPriority:1){
		id,name,image,banner,appUrl,androidPackageName,isDesktopGame,desktopImage
		}
		}`;
};

export const GetContentLocales = () => {

	return `query GetContentLocales{  
		contentLocales{
		 ...ContentLocales
		}
	}
   ${ contentLocale }`;
};

export const getMutedUsersQuery = ( { pageNo, pageSize } ) => {
	return `query getBannedUsers {
		getBannedUsers(pageNo: ${ pageNo }, pageSize: ${ pageSize }) {
			paginatedBannedUsersList {
				id
				isCeleb
				name
				photo
				isFollowing
			}
			totalCount
		}
	}`;
};

export const getConcurrentViewersPerTimeLapsedById = ( { broadcastSessionId } ) => {
	return `query {
		concurrentViewersPerTimeLapsedById(broadcastSessionId:${ broadcastSessionId }) {
		  broadcastSessionId
		  unixTimestamp
		  viewers
		  newFollowersGained
		}
	  }`;
};

export const TopDonorLeaderboardQuery = () => {
	return `query TopDonorLeaderboard($broadcastSessionId: Int!, $pageNo: Int, $pageSize: Int){
		streamDonorsLeaderboard(broadcastSessionId: $broadcastSessionId, pageNo:  $pageNo, pageSize: $pageSize){
			__typename
			currentUser {
				userId
				userSportsFanId
				donation
				rank
				sportsFan {
				  	__typename
					id
					isCeleb
					name
					photo
					isFollowing
				}
			  }
			  leaderboard {
				userId
				userSportsFanId
				donation
				rank
				sportsFan {
				  	__typename
					id
					isCeleb
					name
					photo
					isFollowing
				}
			  }
			  totalDonation
		}
	}`;
};

export const getBroadcastSessionQuery = () => {
	return `
	query broadcastSession($sessionId: Int!) {
		broadcastSession(id: $sessionId){
			id
			broadcasterSportsFanId
			userSportsFanId
			broadcasterId
			broadcaster{
				id
				sportsFan {
							id
							name
							isCeleb
							photo
							city
							locale
							isFollowing
							followingStatus {
								isFollowing
								createdAt
							}
							fanType
							userSportsFanId
						}
			}
		}
	}`;
};

export const getAllIVSPollForBroadcast = () => {
	return `query getAllIVSPollForBroadcast ($sessionId: Int!) {
				getAllIVSPollForBroadcast(sessionId: $sessionId){    
					polls {
						id,
						title,
						options{
						  id,
						  title,
						  votes
						},
						hasVoted,
						isActive,
						creator { 
							name,
							photo,
							isCeleb
						  },
					  	createdAt
					  }
			  }}
		
			`;
};

export const voteOnIvsPollByUser = () => {
	return `query voteOnIvsPollByUser ($sessionId: Int!, $pollId: Int!, $optionId: Int!) {
				voteOnIvsPollByUser(sessionId: $sessionId, pollId: $pollId, optionId: $optionId){    
					status
			  	}
			}
			`;
};

export const GetStreamDownloadStatus = ( { broadcastSessionId } ) => {
	return `
	query getStreamDownloadStatus {
		getStreamDownloadStatus(broadcastSessionId: ${ broadcastSessionId }) {
			sessionId,
			status, 
			downloadURL,
			downloadCompletedAt
		}
	}`;
};

export const getTagsByGenre = ( { irlTag, broadcastTag } ) => {
	return `query {
		getTagsByGenre(irlTag:${ irlTag }, broadcastTag:${ broadcastTag })
	  }`;
};

export const fetchPreviousComments = () => {
	return `query GetPreviousMessagesInSession($broadcastSessionId: Int!, $timestamp: String!, $pageSize: Int, $lastEvaluatedKey: String) {
		getPreviousMessagesInSession(broadcastSessionId: $broadcastSessionId, timestamp: $timestamp, pageSize: $pageSize, lastEvaluatedKey: $lastEvaluatedKey) {
		  __typename
		  comments {
			__typename
			...CommentSchema
		  }
		  lastEvaluatedKey
		}
	  } ${ CommentSchema }`;
};

export const getUserMonetizationStatus = () => {

	return `
	query GetUserMonetizationStatus {
		status:userMonetizationStatus {
		  level
		  image
		  description
		  currentLevel
		  title
		  showPopUp
		  totalHoursRequired
		  totalHoursCompleted
		  totalFollowersRequired
		  totalFollowersCompleted
		}
	  }`;
};

export const updateMonetizationPopup = () => {

	return `
	mutation UpdateMonetizationPopup{
		updateMonetizationPopup
	}`;
};
