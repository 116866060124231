import { BroadcastSession } from "./fragments";

export const _startLiveBroadcast = ( {
	title,
	androidPackageName,
	streamKey,
	streamName,
	ivsChannelId,
	locale,
	mediaType,
	sessionType,
	description,
	gameName,
	resolution,
	thumbnailUrl,
	recordStream,
	overlayUrl,
	donationGoal,
	followerOnlyChat,
	donorOnlyChat,
	tagIds
}, userData ) => {
	const uniqueId = JSON.stringify ( userData.userId ) + JSON.stringify ( Date.now () );

	return `mutation startLiveBroadcast {
					  startLiveBroadcast(
							agoraSessionId: ${ JSON.stringify ( uniqueId ) }
							sessionInfo: ${ JSON.stringify ( title ) }
							androidPackageName: ${ JSON.stringify ( androidPackageName ) }
							streamKey: ${ JSON.stringify ( streamKey ) }
							cssStreamName: ${ JSON.stringify ( streamName ) }
							ivsChannelId: ${ JSON.stringify ( ivsChannelId ) }
							localeKey: ${ JSON.stringify ( locale ) }
							mediaType: ${ JSON.stringify ( mediaType ) }
							sessionType: ${ JSON.stringify ( sessionType ) }
							gameName: ${ JSON.stringify ( gameName ) }
							description: ${ JSON.stringify ( description ) }
							resolution: ${ resolution ? JSON.stringify ( resolution ) : null }
							thumbnailUrl: ${ thumbnailUrl ? JSON.stringify ( thumbnailUrl ) : null }
							recordStream: ${ recordStream ? recordStream : 0 }
							overlayUrl: ${ overlayUrl ? JSON.stringify ( overlayUrl ) : null }
							donationGoal: ${ donationGoal ? JSON.stringify ( donationGoal ) : null }
              followerOnlyChat: ${ followerOnlyChat }
              donorOnlyChat: ${ donorOnlyChat }
							tagIds: ${ tagIds ? JSON.stringify ( tagIds ) : "[]" }
					  ){    
						  __typename
						  ...BroadcastSession
					  }
				  }
				${ BroadcastSession }
			  `;
};

export const endBroadcastSession = ( { broadcastSessionId } ) => {
	return `mutation endBroadcastSession {                                                                                                                              
						  endBroadcastSession(
							  broadcastSessionId:${ broadcastSessionId }
						  ){    
							  __typename
							  ...BroadcastSession
						  }
					  }
					${ BroadcastSession }
				  `;
};

export const banUserForBroadcaster = () => {
	return `mutation banUserForBroadcaster($userId: Int!, $userSportsFanId: BigInt!, $minutes: Int!, $streamerUserId: BigInt) {     
		banUserForBroadcaster(userId: $userId , userSportsFanId: $userSportsFanId, minutes: $minutes, streamerUserId: $streamerUserId) }`;
};

export const reportBroadcastSession = params => {

	const { feedId, reasonId } = params;

	return `mutation{
		reportSession(
			broadcastSessionId: ${ parseInt ( feedId, 10 ) },
			reasonTypeId: ${ reasonId },
			reported: 1
			)
	}`;
};

export const pinComment = ( { broadcastSessionId, commentId, flag } ) => {
	return `mutation{ 
				pinComment(
					  broadcastSessionId : ${ broadcastSessionId },
					  commentId          : ${ commentId },
					  flag               : ${ flag }
				)
			}`;
};

export const purchaseOverlay = ( { overlayId } ) => {
	return `mutation purchaseOverlay {
					purchaseOverlay(overlayId: ${ overlayId })
				  }`;
};

export const purchaseThumbnail = ( { thumbnailId } ) => {
	return `mutation purchaseThumbnail {
                  purchaseThumbnail(thumbnailId: ${ thumbnailId })
				  }`;
};

export const purchaseStreamingTool = ( { toolId, levelId } ) => {
	return `mutation purchaseStreamingTool {
                  purchaseStreamingTool(toolId: ${ toolId } levelId: ${ levelId }) {
                    coins
                  }
                }`;
};

export const giveawayCoins = ( { fanRankAmount } ) => {
	return `mutation giveawayCoins {
                    giveAwayCoins(coins: ${ fanRankAmount })
                }`;
};

export const setRtmpStreamAdditionalInfo = ( { streamKey, streamDelay, overlayUrl } ) => {
	return `mutation setRtmpStreamAdditionalInfo {                                                                                                                      
          setRtmpStreamAdditionalInfo(streamKey: ${ JSON.stringify ( streamKey ) }, streamDelay: ${ streamDelay }, overlayUrl: ${ JSON.stringify ( overlayUrl ) })
        }`;
};

export const setOnboardingFavoriteGames = games => {

	return `mutation FollowGame{
		val : followGame(gameIds: [${ games }])
	   }`;
};

export const addModeratorMutation = () => {
	return `mutation addModerator($moderatorUserIds: [BigInt!]!) {
		addModerator(moderatorUserIds: $moderatorUserIds)
	}`;
};

export const getModeratorQuery = () => {
	return `query getModerators($pageNo: Int, $pageSize: Int) {
		getModerators(pageNo: $pageNo, pageSize: $pageSize) {
			id
			name
			isCeleb
			photo
			city
			locale 
			shortBio
		}
	}`;
};

export const removeModeratorMutation = () => {
	return `mutation removeModerator($moderatorUserIds: [BigInt!]!) {
		removeModerator(moderatorUserIds: $moderatorUserIds)
	}
	`;
};

export const deleteCommentMutation = ( { broadcastSessionId, commentId } ) => {
	return `mutation {
		deleteCommentOnBroadcast(broadcastSessionId: ${ broadcastSessionId }, commentId: ${ commentId })
	}`;
};

export const unmuteUserMutation = () => {
	return `mutation unBanUserForBroadcaster($userId: BigInt!) {
		unBanUserForBroadcaster(userId: $userId)
	}`;
};

export const broadcastSessionMutation = type => {
	if ( type === "chatModeUpdate" ) {
		return `mutation updateSessionConfig ($sessionId : Int!, $followerOnlyChat : Boolean, $donorOnlyChat: Boolean) {
				updateSessionConfig(
					sessionId:$sessionId, 
					followerOnlyChat: $followerOnlyChat,
					donorOnlyChat: $donorOnlyChat,
				){
          followerOnlyChat
        }
			}`;
	}

	if ( type === "editSettings" ) {
		return `mutation updateSessionConfig ($sessionId : Int!, $title: String, $description: String, $thumbnailUrl : String) {
		updateSessionConfig(
			sessionId:$sessionId, 
			sessionInfo: $title,
			description: $description,
			thumbnail: $thumbnailUrl
		){
		followerOnlyChat
	}
	}`;
	}
};

export const streamFeedbackMutation = () => {
	return `mutation ($broadcastSessionId: BigInt! , $streamRating: Int!) {
		streamFeedback (broadcastSessionId: $broadcastSessionId, streamRating: $streamRating) {
		  streamRating
		}
	  }
	`;
};

export const donationGoalMutation = () => {
	return `mutation ($broadcastSessionId: Int!, $donationGoal: Int!) {
		setDonationGoal (broadcastSessionId: $broadcastSessionId, donationGoal: $donationGoal) {
			donationGoal
		}
	  }
	`;
};

export const thankForDonationMutation = () => {
	return `mutation ($broadcastSessionId: Int!, $commentId: Int!) {
		thankForDonation (broadcastSessionId: $broadcastSessionId, commentId: $commentId) {
			id
		}
	  }
	`;
};

export const deleteLive = sessionId => {
	return `
          mutation deleteLive { 
            deleteSession(sessionId: ${ sessionId }) 
        }`;
};

export const createIVSPoll = () => {
	return `mutation createIVSPoll($sessionId: Int!, $title: String!, $options: [String]) {
		createIVSPoll(sessionId: $sessionId, title: $title, options: $options){
				status,
				pollId
		}
	}`;
};

export const updateIVSPoll = () => {
	return `mutation updateIVSPoll($sessionId: Int!, $pollId: Int!, $deleted: Boolean, $isActive: Boolean) {
		updateIVSPoll(sessionId: $sessionId, pollId: $pollId, deleted: $deleted, isActive: $isActive){
				status
		}
	}`;
};

export const downloadStream = ( { broadcastSessionId } ) => {
	return `
          mutation downloadStream { 
            downloadStream(broadcastSessionId: ${ broadcastSessionId }) 
        }`;
};
